<template>
  <b-container
    v-show="!isLoggedIn"
    fluid="md"
    class="create-user"
    @keyup.enter="login"
  >
    <h1>{{ $t("pages.addUser.header") }}</h1>
    <b-form id="create-new">
      <b-row class="form-group">
        <b-col cols="4">
          <label for="username">{{ $t("pages.addUser.username") }}</label>
          <b-form-input
            id="username"
            v-model="username"
            name="username"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row class="form-group">
        <b-col cols="4">
          <label for="password">{{ $t("pages.addUser.password") }}</label>
          <b-form-input
            id="password"
            v-model="password"
            class="input-field"
            type="password"
            name="password"
        /></b-col>
      </b-row>
      <b-row class="form-group">
        <b-col cols="4">
          <label for="email">{{ $t("pages.addUser.email") }}</label
          ><b-form-input
            id="email"
            v-model="email"
            class="input-field"
            type="email"
            name="email"
        /></b-col>
      </b-row>
      <b-row class="form-group form-group-buttons">
        <b-col>
          <b-button variant="primary" @click="saveUser">{{
            $t("general.save")
          }}</b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
export default {
  name: "CreateUser",
  data() {
    return {
      username: "",
      firstname: "",
      lastname: "",
      password: "",
      email: ""
    };
  },
  metaInfo() {
    return {
      title: this.$t("pages.addUser.header")
    };
  },
  methods: {
    saveUser: function(ev) {
      ev.preventDefault();
      //TODO: Post to localhost:8080/user/create
      //console.log("Hello " + this.username + "!");
    }
  }
};
</script>
